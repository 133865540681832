/* eslint-disable no-shadow */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'
import Assortiment from 'components/shared/Assortiment'
import ButtonDefault from 'components/elements/ButtonDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'

const OrderRow = styled.div`
  background-color: ${(props) => props.theme.color.face.main};
`

const OrderTitle = styled(ParseContent)`
  & h2 {
    font-size: ${(props) => props.theme.font.size.xml};
    line-height: ${(props) => props.theme.font.size.xml};
    color: ${(props) => props.theme.color.text.light};
    margin-bottom: -5px;
  }
`

const OrderFish = styled.section`
  background-color: rgba(25, 153, 214, 0.45);
`

const ButtonFile = styled.a`
  ${(props) =>
    props.dark &&
    css`
      background-color: ${(props) => props.theme.color.face.main} !important;
    `}

  background-color: ${(props) => props.theme.color.face.contrast};
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: ${(props) => props.theme.font.size.xm};
  color: ${(props) => props.theme.color.text.light} !important;
  letter-spacing: 1.43px;
  padding: 11px 35px 8px;
  width: 100%;
  height: 100%;
  display: block;
  min-width: 180px;
  text-align: center;
`

const ButtonNew = styled(ButtonDefault)`
  ${(props) =>
    props.dark &&
    css`
      background-color: ${(props) => props.theme.color.face.main} !important;
    `}

  width: 100%;
  min-width: 180px;
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />

      <section className="intro">
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-5 pt-4 mb-4 mb-lg-0">
              <TitleDefault>{acf.intro.title}</TitleDefault>
              <ParseContent
                className="pr-5 mb-4"
                content={acf.intro.description}
              />
              <Assortiment noImage />
            </div>
            <div className="col-lg-7">
              <Image src={acf.intro.image.localFile.publicURL} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row py-lg-5">
            <OrderRow className="col-12 p-4 d-flex justify-content-between align-items-center flex-wrap">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <OrderTitle content={acf.orderlist.title} />
              </div>
              <div className="col-lg-6 d-flex flex-lg-row flex-column justify-content-between">
                <ButtonNew to={acf.orderlist.link.url}>
                  {acf.orderlist.link.title}
                </ButtonNew>
                <ButtonFile
                  className="ml-lg-3 mt-3 mt-lg-0"
                  href={acf.orderlist.secondLink.url.localFile.url}
                  target="_blank"
                >
                  {acf.orderlist.secondLink.title}
                </ButtonFile>
              </div>
            </OrderRow>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <Assortiment allVisible />
        </div>
      </section>

      <OrderFish>
        <div className="container">
          <div className="row align-items-center py-5">
            <div className="col-lg-8">
              <ParseContent content={acf.orderFish.title} className="mb-3" />
              <ParseContent content={acf.orderFish.description} />
            </div>
            <div className="col-lg-4">
              <ButtonNew to={acf.orderFish.buttonOne.url} className="mb-3">
                {acf.orderFish.buttonOne.title}
              </ButtonNew>
              <br />
              <ButtonFile
                dark
                href={acf.orderFish.buttonTwo.url.localFile.url}
                target="_blank"
              >
                {acf.orderFish.buttonTwo.title}
              </ButtonFile>
            </div>
          </div>
        </div>
      </OrderFish>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        intro {
          title
          description
          image {
            localFile {
              publicURL
            }
          }
        }
        orderlist {
          title
          link {
            title
            url
          }
          secondLink: second_link {
            title
            url {
              localFile {
                url
              }
            }
          }
        }
        orderFish: order_fish {
          title
          description
          buttonOne: button_one {
            title
            url
          }
          buttonTwo: button_two {
            title
            url {
              localFile {
                url
              }
            }
          }
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
